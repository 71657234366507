body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

td .btn-list > .btn {
  margin-bottom: 0;
}

td .btn-list > a:not(:last-child) {
  margin-right: 0.5rem;
}

@media (min-width: 1280px) {
  .container {
    max-width: 95% !important;
  }
}